export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: 'rgba(23, 70, 161, .11)',
  type: 'light',
  primary: {
    main: '#00D2F0',
    light: '#E5FBFE',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: 'rgba(229, 251, 254, 0.8)',
    contrastText: '#0A5285',
    light: '#D9EDF7'
  },
  text: {
    primary: '#141B22',
    secondary: '#5A7184',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
    arrow:'#292D32'
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#FFFFFF',
    paper: '#FFF',
    default: '#FFF',
    header: 'rgba(229, 251, 254, 0.8)',
    footer: 'rgba(229, 251, 254, 0.8)',
    level2: '#f7f8fb',
    level1: '#FFF',
    steps: '#43C2BD',
    tableHead: '#00D2F0',
    tableSubhead: '#F7F7F7',
    tableBorder: '#7D808880',
    tableAlternate: '#F8F8F8',
    alternate: '#d4f1f9',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: '#2D2F34',
    secondary: '#1D3650',
    light: '#E5FBFE',
    arrow: '#BAEAFF',
    secondaryLight: 'rgba(238, 246, 250, 0.6)'
  },
  verticalBar: {
    used: '#00D2F0',
    unused: '#E5FBFE',
    unlimited: '#C0C0C0',
  }
};